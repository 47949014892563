import React, { Component } from "react";
import Contadores from "./Contadores";
import Indicadores from "./Indicadores";
import GC from "./GC1";
import GV from "./GV1";
import GBEagle from "./GBEagle";
import GTEagle from "./GTEagle";
import GSignal from "./GTSignal";
import GVelocidad from "./GVelocidad";

export class Page extends Component {
  render() {
    function renderi(num) {
      if (num.length > 0) {
        return (
          <section className="widget-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-12 page-title">
                  <h2>{num[0].nombresitio[0].nombre}</h2>
                </div>
                <div className="col-md-6 col-sm-12 add-text">
                  <a href={`#1`}>{num[6].estatus}</a>
                </div>
              </div>
              <div className="content-area">
                <Contadores lecturas={num[2].ultimalectura} />
                <Indicadores
                  voldia={num[5].voldia}
                  volsem={num[4].volsem}
                  volmes={num[3].volmes}
                />
                <div className="about-sec-2">
                  <div className="row">
                    <GC lecturas={num[1].lecturas} />
                    <GV lecturas={num[1].lecturas} />
                    <GTEagle lecturas={num[1].lecturas} />
                    <GVelocidad lecturas={num[1].lecturas} />
                    <GBEagle lecturas={num[1].lecturas} />
                    <GSignal lecturas={num[1].lecturas} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
      } else {
        return (
          <div className="page">
            <h1>Cargando...</h1>
          </div>
        );
      }
    }

    return renderi(this.props.lecturas);
  }
}

export default Page;
