import React, { Component } from "react";
import iconCaudal from "../../images/ico-wa.png";
import iconVol from "../../images/icon-2.png";
import iconFecha from "../../images/ico-cal.png";
import iconsenal from "../../images/ico-sginal.png";
import iconbat from "../../images/ico-battery.png";
import icontemp from "../../images/icon-wat.png";

export class Contadores extends Component {
  render() {
    function contadores(s) {
      return (
        <div className="row">
          <div
            className="col-lg-2 col-md-4 col-sm-6 col-xs-6 widget-info"
            style={{ maxWidth: 165 }}
          >
            <div className="row">
              <div className="icon-img">
                <img src={iconCaudal} alt="Water Icon" />
              </div>
              <div className="description">
                <h5>Caudal</h5>
                <p>
                  m<sup>3</sup>/s
                </p>
                <h3>
                  {s.caudal === null || isNaN(s.caudal)
                    ? "null"
                    : s.caudal.toFixed(3)}
                </h3>
              </div>
            </div>
          </div>
          <div
            className="col-lg-2 col-md-4 col-sm-6 col-xs-6 widget-info"
            style={{ maxWidth: 165 }}
          >
            <div className="row">
              <div className="icon-img">
                <img src={iconVol} alt="Water Icon" />
              </div>
              <div className="description">
                <h5>Volumen</h5>
                <p>
                  m<sup>3</sup>
                </p>
                <h3>{s.volumen2 === null ? "null" : s.volumen2}</h3>
              </div>
            </div>
          </div>
          <div
            className="col-lg-2 col-md-4 col-sm-6 col-xs-6 widget-info"
            style={{ maxWidth: 165 }}
          >
            <div className="row">
              <div className="icon-img">
                <img src={icontemp} alt="Water Icon" />
              </div>
              <div className="description">
                <h5>Nivel</h5>
                <p>m</p>
                <h3>{s.volumen === null ? "null" : s.volumen}</h3>
              </div>
            </div>
          </div>

          <div
            className="col-lg-2 col-md-4 col-sm-6 col-xs-6 widget-info"
            style={{ maxWidth: 165 }}
          >
            <div className="row">
              <div className="icon-img">
                <img src={icontemp} alt="Water Icon" />
              </div>
              <div className="description">
                <h5>Velocidad</h5>
                <p>m/s</p>
                <h3>{s.batm === null ? "null" : s.batm}</h3>
              </div>
            </div>
          </div>

          <div
            className="col-lg-2 col-md-4 col-sm-6 col-xs-6 widget-info"
            style={{ maxWidth: 165 }}
          >
            <div className="row">
              <div className="icon-img">
                <img src={iconbat} alt="Water Icon" />
              </div>
              <div className="description">
                <h5>Batería</h5>
                <p>V</p>
                <h3>{s.bateria === null ? "null" : s.bateria}</h3>
              </div>
            </div>
          </div>
          <div
            className="col-lg-2 col-md-4 col-sm-6 col-xs-6 widget-info"
            style={{ maxWidth: 165 }}
          >
            <div className="row">
              <div className="icon-img">
                <img src={iconsenal} alt="Water Icon" />
              </div>
              <div className="description">
                <h5>Calidad de Señal</h5>
                <p>Porcentaje</p>
                <h3>
                  {s.senal === null || s.senal === undefined
                    ? "null"
                    : (1.612 * (-1 * s.senal + 113)).toFixed(3)}
                  %
                </h3>
              </div>
            </div>
          </div>
          <div
            className="col-lg-2 col-md-4 col-sm-6 col-xs-6 widget-info"
            style={{ maxWidth: 300 }}
          >
            <div className="row">
              <div className="icon-img">
                <img src={iconFecha} alt="Water Icon" />
              </div>
              <div className="description">
                <h5>Fecha y Hora</h5>
                <p>última Lectura</p>
                <h3>{s.fechalec}</h3>
                {/*<h3>07/01/20</h3>
                                        <p>19:00:00</p>*/}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return contadores(this.props.lecturas);
  }
}

export default Contadores;
