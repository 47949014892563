import React, { Component } from "react";
import CuadroAlertas from "./CuadroAlertas";
import axios from "axios";
import L from "leaflet";
/*import L2 from '@mapbox/leaflet-omnivore';
import kml from './kml/doc.kml';
import kml2 from './kml/canipia-pozos.kml';
import kml3 from './kml/lineasdeagua.kml';
import kml4 from './kml/tajo.kml';
import kml5 from './kml/cocopros.kml';
import kml6 from './kml/coco.geojson';*/
import styled from "styled-components";
import iconVerde from "./img/gotaverde.png";
import iconRojo from "./img/gotaroja.gif";
import iconVerdeP from "./img/gotaverdep.gif";
import iconAmarillo from "./img/gotaamarilla.gif";
import iconNegro from "./img/gotanegra.png";
/*iconos Caudal*/
import caudalverdep from "./img/caudal/caudalverdep.gif";
import caudalverde from "./img/caudal/gotaverdecaudal.png";
import caudalama from "./img/caudal/caudalamap25.gif";
//import caudalnaranja from './img/caudal/caudalnaranjap25.gif'
import caudalrojo from "./img/caudal/caudalrojop25.gif";
import caudalnegro from "./img/caudal/caudalnegrop25.gif";
/*iconos Nivel */
import nivelverdep from "./img/nivel/nivelverde25.gif";
import nivelverde from "./img/nivel/nivelverde.png";
import nivelama from "./img/nivel/nivelama25.gif";
//import nivelnaranja from './img/nivel/nivelnaranja25.gif'
import nivelrojo from "./img/nivel/nivelrojo25.gif";
import nivelnegro from "./img/nivel/nivelnegro25.gif";
/*iconos multiparametros */
import multiverdep from "./img/multiparmetros/multiverde25.gif";
import multiverde from "./img/multiparmetros/multi25.png";
import multiama from "./img/multiparmetros/multiamarillo25.gif";
//import multinaranja from './img/multiparmetros/multinaranja25.gif';
import multirojo from "./img/multiparmetros/multirojo25.gif";
import multinegro from "./img/multiparmetros/multinegro25.gif";
/*iconos PLC*/
import plcverdep from "./img/plc/plcverde25.gif";
import plcverde from "./img/plc/verde25.png";
import plcama from "./img/plc/plcamarillo25.gif";
//import plcnaranja from './img/plc/plcnaranja25.gif';
import plcrojo from "./img/plc/plcroja25.gif";
import plcnegro from "./img/plc/plcnegro25.gif";

import { withRouter } from "react-router-dom";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
const Wrapper = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

class CargarPuntos2 extends Component {
  state = {
    sitios: [],
    contador: 1
  };

  async componentDidMount() {
    //pedir al servidor
    const res = await instance.get(
      process.env.REACT_APP_URL_MACHOTE + "mapa/nuevo/dr075/7"
    );
    this.setState({ sitios: res.data });
    console.log(this.state.sitios);
    // NOTE: Layer is created here!
    var marker;

    var satelite2 = L.tileLayer(
      "https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      {
        maxZoom: 20,
        subdomains: ["mt0", "mt1", "mt2", "mt3"]
      }
    );
    this.map = L.map("map", {
      center: [24.433909, -107.119355],
      zoom: 5,
      zoomControl: true,
      layers: [satelite2]
    });
    /* var customLayer = L.geoJson(null, {            
            style: function(feature) {
                return { weight: 2 };
            }
        });
        var topo_layer = L2.kml(kml,null,customLayer).on('ready', function(){console.log('mapa orografica cargado')});  
        var tubconfig = L.geoJson(null, {            
            style: function(feature) {
                return { color: "#4bc51d" };
            }
        });
        var lineas_layer = L2.kml(kml3,null,tubconfig); 
        
        var canipiaconf = L.geoJson(null, {            
            style: function(feature) {
                return { color: "#c51d34" };
            }
        });
        var canipia_layer = L2.kml(kml2,null,canipiaconf); 

        var tajoconf = L.geoJson(null, {            
            style: function(feature) {
                return { color: "#f0ff0b" };
            }
        });
        var tajo_layer = L2.kml(kml4,null,tajoconf);
        
        var cococonf = L.geoJson(null, {            
            style: function(feature) {
                return { color: "#000" };
            }
        });
        var coco_layer = L2.kml(kml5,null,cococonf);
        
        var cococonf2 = L.geoJson(null, {            
            style: function(feature) {
                return { color: "#00D" };
            }
        });
        var coco_layer2 = L2.geojson(kml6,null,cococonf2);*/

    var markersLayer = L.layerGroup().addTo(this.map);
    var markersLayer_2 = L.layerGroup().addTo(this.map);
    var markersLayer_3 = L.layerGroup().addTo(this.map);

    var GotaIcono = L.Icon.extend({
      options: {
        iconSize: [16, 16],
        popupAnchor: [0, 0],
        iconAnchor: [0, 0]
      }
    });

    var gotaverde = new GotaIcono({ iconUrl: iconVerde }),
      gotarojo = new GotaIcono({ iconUrl: iconRojo }),
      gotaverdepar = new GotaIcono({ iconUrl: iconVerdeP }),
      gotaamarillo = new GotaIcono({ iconUrl: iconAmarillo }),
      gotanegra = new GotaIcono({ iconUrl: iconNegro }),
      iconcaudalverdep = new GotaIcono({ iconUrl: caudalverdep }),
      iconcaudalverde = new GotaIcono({ iconUrl: caudalverde }),
      iconcaudalrojo = new GotaIcono({ iconUrl: caudalrojo }),
      iconcaudalamarillo = new GotaIcono({ iconUrl: caudalama }),
      // iconcaudalnaranja = new GotaIcono({iconUrl: caudalnaranja}),
      iconcaudalnegro = new GotaIcono({ iconUrl: caudalnegro }),
      iconnivelverdep = new GotaIcono({ iconUrl: nivelverdep }),
      iconnivelverde = new GotaIcono({ iconUrl: nivelverde }),
      iconnivelrojo = new GotaIcono({ iconUrl: nivelrojo }),
      iconnivelamarillo = new GotaIcono({ iconUrl: nivelama }),
      //iconnivellnaranja = new GotaIcono({iconUrl: nivelnaranja}),
      iconnivellnegro = new GotaIcono({ iconUrl: nivelnegro }),
      imultiverdep = new GotaIcono({ iconUrl: multiverdep }),
      imultiverde = new GotaIcono({ iconUrl: multiverde }),
      imultiama = new GotaIcono({ iconUrl: multiama }),
      // imultinaranja = new GotaIcono({iconUrl: multinaranja}),
      imultirojo = new GotaIcono({ iconUrl: multirojo }),
      imultinegro = new GotaIcono({ iconUrl: multinegro }),
      iplcverdep = new GotaIcono({ iconUrl: plcverdep }),
      iplcverde = new GotaIcono({ iconUrl: plcverde }),
      iplcama = new GotaIcono({ iconUrl: plcama }),
      // iplcnaranja = new GotaIcono({iconUrl: plcnaranja}),
      iplcrojo = new GotaIcono({ iconUrl: plcrojo }),
      iplcnegro = new GotaIcono({ iconUrl: plcnegro });

    var customOptions = {
      maxWidth: "500",
      className: "custom"
    };

    //funcion para armar las gotas y sus respectivos iconos
    var customPopup = "";
    var ic;
    for (var i in this.state.sitios) {
      var latlng = L.latLng({
        lat: this.state.sitios[i].lat,
        lng: this.state.sitios[i].long
      });

      // console.log( this.state.sitios[i]);

      if (this.state.sitios[i].tipo === 1) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
          (this.state.sitios[i].caudal === null ||
          this.state.sitios[i].caudal === "red intermitente" ||
          this.state.sitios[i].caudal === null
            ? this.state.sitios[i].caudal
            : this.state.sitios[i].caudal.toFixed(3)) +
          " L/S</td><th scope='row'>Volumen:</th><td>" +
          this.state.sitios[i].volumen +
          " M3</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalectura +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = iconcaudalverde;
        } else if (this.state.sitios[i].estatus === "mayor") {
          ic = iconcaudalrojo;
        } else if (this.state.sitios[i].estatus === "cero") {
          ic = iconcaudalamarillo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = iconcaudalamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = iconcaudalverdep;
        } else if (this.state.sitios[i].estatus === "noconexion") {
          ic = iconcaudalnegro;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 2) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
          (this.state.sitios[i].caudal1 === null ||
          this.state.sitios[i].caudal1 === "red intermitente"
            ? this.state.sitios[i].caudal1
            : this.state.sitios[i].caudal1.toFixed(3)) +
          " L/S</td><th scope='row'>Volumen:</th><td>" +
          this.state.sitios[i].volumen1 +
          " M3</td></tr><tr><th scope='row'>Caudal:</th><td>" +
          (this.state.sitios[i].caudal2 === null ||
          this.state.sitios[i].caudal2 === "red intermitente"
            ? this.state.sitios[i].caudal2
            : this.state.sitios[i].caudal2.toFixed(3)) +
          " L/S</td><th scope='row'>Volumen:</th><td>" +
          this.state.sitios[i].volumen2 +
          " M3</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalectura +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = iconcaudalverde;
        } else if (this.state.sitios[i].estatus === "mayor") {
          ic = iconcaudalrojo;
        } else if (this.state.sitios[i].estatus === "cero") {
          ic = iconcaudalamarillo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = iconcaudalamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = iconcaudalverdep;
        } else if (this.state.sitios[i].estatus === "noconexion") {
          ic = iconcaudalnegro;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 8) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Caudal 1:</th><td>" +
          (this.state.sitios[i].caudal1 === null ||
          this.state.sitios[i].caudal1 === "red intermitente" ||
          this.state.sitios[i].caudal1 === null
            ? this.state.sitios[i].caudal1
            : this.state.sitios[i].caudal1.toFixed(3)) +
          " L/S</td><th scope='row'>Volumen 1:</th><td>" +
          this.state.sitios[i].volumen1 +
          " M3</td></tr>";
        if (this.state.sitios[i].volumen2 !== null) {
          customPopup =
            customPopup +
            "<tr><th scope='row'>Caudal 2:</th><td>" +
            (this.state.sitios[i].caudal2 === null ||
            this.state.sitios[i].caudal2 === "red intermitente" ||
            this.state.sitios[i].caudal2 === null
              ? this.state.sitios[i].caudal2
              : this.state.sitios[i].caudal2.toFixed(3)) +
            " L/S</td><th scope='row'>Volumen 2:</th><td>" +
            this.state.sitios[i].volumen2 +
            " M3</td></tr>";
        }
        if (this.state.sitios[i].volumen3 !== null) {
          customPopup =
            customPopup +
            "<tr><th scope='row'>Caudal 3:</th><td>" +
            (this.state.sitios[i].caudal3 === null ||
            this.state.sitios[i].caudal3 === "red intermitente" ||
            this.state.sitios[i].caudal3 === null
              ? this.state.sitios[i].caudal3
              : this.state.sitios[i].caudal3.toFixed(3)) +
            " L/S</td><th scope='row'>Volumen 3:</th><td>" +
            this.state.sitios[i].volumen3 +
            " M3</td></tr>";
        }
        if (this.state.sitios[i].volumen4 !== null) {
          customPopup =
            customPopup +
            "<tr><th scope='row'>Caudal 4:</th><td>" +
            (this.state.sitios[i].caudal4 === null ||
            this.state.sitios[i].caudal4 === "red intermitente" ||
            this.state.sitios[i].caudal4 === null
              ? this.state.sitios[i].caudal4
              : this.state.sitios[i].caudal4.toFixed(3)) +
            " L/S</td><th scope='row'>Volumen 4:</th><td>" +
            this.state.sitios[i].volumen4 +
            " M3</td></tr>";
        }
        customPopup =
          customPopup +
          "<tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalectura +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = iconcaudalverde;
        } else if (this.state.sitios[i].estatus === "mayor") {
          ic = iconcaudalrojo;
        } else if (this.state.sitios[i].estatus === "cero") {
          ic = iconcaudalamarillo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = iconcaudalamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = iconcaudalverdep;
        } else if (this.state.sitios[i].estatus === "noconexion") {
          ic = iconcaudalnegro;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 3) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
          (this.state.sitios[i].cota === null ||
          this.state.sitios[i].cota === "red intermitente"
            ? this.state.sitios[i].cota
            : this.state.sitios[i].cota.toFixed(3)) +
          " msnm</td><th scope='row'>Profundidad:</th><td>" +
          this.state.sitios[i].profundidad +
          " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
          this.state.sitios[i].temperatura +
          " °C</td><th scope='row'>Presión:</th><td>" +
          this.state.sitios[i].presion +
          " cm H2O</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalectura +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = iconnivelverde;
        } else if (this.state.sitios[i].estatus === "mayor") {
          ic = iconnivelrojo;
        } else if (this.state.sitios[i].estatus === "cero") {
          ic = iconnivelamarillo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = iconnivelamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = iconnivelverdep;
        } else if (this.state.sitios[i].estatus === "noconexion") {
          ic = iconnivellnegro;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer_2.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 4) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
          (this.state.sitios[i].cota1 === null ||
          this.state.sitios[i].cota1 === "red intermitente"
            ? this.state.sitios[i].cota1
            : this.state.sitios[i].cota1.toFixed(3)) +
          " msnm</td><th scope='row'>Profundidad:</th><td>" +
          (this.state.sitios[i].profundidad1 === null ||
          this.state.sitios[i].profundidad1 === "red intermitente"
            ? this.state.sitios[i].profundidad1
            : this.state.sitios[i].profundidad1.toFixed(3)) +
          " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
          this.state.sitios[i].temperatura1 +
          " °C</td><th scope='row'>Presión:</th><td>" +
          (this.state.sitios[i].presion1 === null ||
          this.state.sitios[i].presion1 === "red intermitente"
            ? this.state.sitios[i].presion1
            : this.state.sitios[i].presion1.toFixed(3)) +
          " MPa</td></tr>";
        if (this.state.sitios[i].cota2 !== null) {
          customPopup =
            customPopup +
            "<tr><th scope='row'>Cota:</th><td>" +
            (this.state.sitios[i].cota2 === null ||
            this.state.sitios[i].cota2 === "red intermitente"
              ? this.state.sitios[i].cota2
              : this.state.sitios[i].cota2.toFixed(3)) +
            " msnm</td><th scope='row'>Profundidad:</th><td>" +
            (this.state.sitios[i].profundidad2 === null ||
            this.state.sitios[i].profundidad2 === "red intermitente"
              ? this.state.sitios[i].profundidad2
              : this.state.sitios[i].profundidad2.toFixed(3)) +
            " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
            this.state.sitios[i].temperatura2 +
            " °C</td><th scope='row'>Presión:</th><td>" +
            (this.state.sitios[i].presion2 === null ||
            this.state.sitios[i].presion2 === "red intermitente"
              ? this.state.sitios[i].presion2
              : this.state.sitios[i].presion2.toFixed(3)) +
            " MPa</td></tr>";
        }
        if (this.state.sitios[i].cota3 !== null) {
          customPopup =
            customPopup +
            "<tr><th scope='row'>Cota:</th><td>" +
            (this.state.sitios[i].cota3 === null ||
            this.state.sitios[i].cota3 === "red intermitente"
              ? this.state.sitios[i].cota3
              : this.state.sitios[i].cota3.toFixed(3)) +
            " msnm</td><th scope='row'>Profundidad:</th><td>" +
            (this.state.sitios[i].profundidad3 === null ||
            this.state.sitios[i].profundidad3 === "red intermitente"
              ? this.state.sitios[i].profundidad3
              : this.state.sitios[i].profundidad3.toFixed(3)) +
            " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
            this.state.sitios[i].temperatura3 +
            " °C</td><th scope='row'>Presión:</th><td>" +
            (this.state.sitios[i].presion3 === null ||
            this.state.sitios[i].presion3 === "red intermitente"
              ? this.state.sitios[i].presion3
              : this.state.sitios[i].presion3.toFixed(3)) +
            " MPa</td></tr>";
        }
        if (this.state.sitios[i].cota4 !== null) {
          customPopup =
            customPopup +
            "<tr><th scope='row'>Cota:</th><td>" +
            (this.state.sitios[i].cota4 === null ||
            this.state.sitios[i].cota4 === "red intermitente"
              ? this.state.sitios[i].cota4
              : this.state.sitios[i].cota4.toFixed(3)) +
            " msnm</td><th scope='row'>Profundidad:</th><td>" +
            (this.state.sitios[i].profundidad4 === null ||
            this.state.sitios[i].profundidad4 === "red intermitente"
              ? this.state.sitios[i].profundidad4
              : this.state.sitios[i].profundidad4.toFixed(3)) +
            " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
            this.state.sitios[i].temperatura4 +
            " °C</td><th scope='row'>Presión:</th><td>" +
            (this.state.sitios[i].presion4 === null ||
            this.state.sitios[i].presion4 === "red intermitente"
              ? this.state.sitios[i].presion4
              : this.state.sitios[i].presion4.toFixed(3)) +
            " MPa</td></tr>";
        }
        customPopup =
          customPopup +
          "<tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalectura +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = iconnivelverde;
        } else if (this.state.sitios[i].estatus === "mayor") {
          ic = iconnivelrojo;
        } else if (this.state.sitios[i].estatus === "cero") {
          ic = iconnivelamarillo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = iconnivelamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = iconnivelverdep;
        } else if (this.state.sitios[i].estatus === "noconexion") {
          ic = iconnivellnegro;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer_2.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 5) {
        // eslint-disable-next-line
        const arra = "Apagado";
        if (this.state.sitios[i].io1 === true) {
          // eslint-disable-next-line
          const arra = "Encendido";
        }

        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
          this.state.sitios[i].caudal +
          " L/S</td><th scope='row'>Volumen:</th><td>" +
          this.state.sitios[i].volumen +
          " M3</td></tr><tr><th colSpan='2' scope='row'>Estatus del Arrancador</th><td>" +
          arra +
          " </td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalectura +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = iplcverde;
        } else if (this.state.sitios[i].estatus === "mayor") {
          ic = iplcrojo;
        } else if (this.state.sitios[i].estatus === "cero") {
          ic = iplcama;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = iplcama;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = iplcverdep;
        } else if (this.state.sitios[i].estatus === "noconexion") {
          ic = iplcnegro;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer_3.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 6) {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody>";

        if (this.state.sitios[i].caudal !== null) {
          customPopup =
            customPopup +
            "<tr><th scope='row'>Caudal:</th><td>" +
            this.state.sitios[i].caudal +
            " L/S</td><th scope='row'>Volumen:</th><td>" +
            this.state.sitios[i].volumen +
            " M3</td></tr>";
        }
        customPopup =
          customPopup +
          "<tr><th scope='row'>Temperatura:</th><td>" +
          this.state.sitios[i].temperatura +
          " C°</td><th scope='row'>Elevación:</th><td>" +
          this.state.sitios[i].elevacion +
          " </td></tr><tr><th scope='row'>Conductividad:</th><td>" +
          this.state.sitios[i].conductividad +
          " </td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalectura +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = imultiverde;
        } else if (this.state.sitios[i].estatus === "mayor") {
          ic = imultirojo;
        } else if (this.state.sitios[i].estatus === "cero") {
          ic = imultiama;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = imultiama;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = imultiverdep;
        } else if (this.state.sitios[i].estatus === "noconexion") {
          ic = imultinegro;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer.addLayer(marker);
      } else if (this.state.sitios[i].tipo === 7) {
        const arra = "Apagado";
        if (this.state.sitios[i].io1 === true) {
          // eslint-disable-next-line
          const arra = "Encendido";
        }

        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
          this.state.sitios[i].caudal +
          " L/S</td><th scope='row'>Volumen:</th><td>" +
          this.state.sitios[i].volumen +
          " M3</td></tr><tr><th colSpan='2' scope='row'>Estatus del Arrancador</th><td>" +
          arra +
          " </td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalectura +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = iplcverde;
        } else if (this.state.sitios[i].estatus === "mayor") {
          ic = iplcrojo;
        } else if (this.state.sitios[i].estatus === "cero") {
          ic = iplcama;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = iplcama;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = iplcverdep;
        } else if (this.state.sitios[i].estatus === "noconexion") {
          ic = iplcnegro;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer_3.addLayer(marker);
      } else {
        customPopup =
          " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
          this.state.sitios[i].nombre +
          "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
          this.state.sitios[i].cota +
          " msnm</td><th scope='row'>Profundidad:</th><td>" +
          this.state.sitios[i].profundidad +
          " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
          this.state.sitios[i].temperatura +
          " °C</td><th scope='row'>Presión:</th><td>" +
          this.state.sitios[i].presion +
          " MPa</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
          this.state.sitios[i].fechalectura +
          "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
          this.state.sitios[i].tipo +
          "/" +
          this.state.sitios[i].idSitio +
          "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

        if (this.state.sitios[i].estatus === "normal") {
          ic = gotaverde;
        } else if (this.state.sitios[i].estatus === "mayor") {
          ic = gotarojo;
        } else if (this.state.sitios[i].estatus === "cero") {
          ic = gotaamarillo;
        } else if (this.state.sitios[i].estatus === "menor") {
          ic = gotaamarillo;
        } else if (this.state.sitios[i].estatus === "redint") {
          ic = gotaverdepar;
        } else if (this.state.sitios[i].estatus === "noconexion") {
          ic = gotanegra;
        }

        marker = L.marker(latlng, { icon: ic }).bindPopup(
          customPopup,
          customOptions
        );
        markersLayer_2.addLayer(marker);
      }
    }
    /*, 'Orográfico':topo_layer, 'Líneas de Agua': lineas_layer , 'Canipia': canipia_layer, 'Tajo ZI':tajo_layer, 'COROCCOHUAYCO pros':coco_layer,'COROCCOHUAYCO GEO':coco_layer2 */
    var overlay = {
      Caudal: markersLayer,
      Cota: markersLayer_2,
      PLC: markersLayer_3
    };
    var baseMaps = {
      Google: satelite2
    };

    L.control.layers(baseMaps, overlay).addTo(this.map);

    this.interval = setInterval(async () => {
      var d = new Date();
      var n = d.getMinutes();
      var seg = d.getSeconds();
      if (
        localStorage.getItem("the_main_app" + process.env.REACT_APP_COOKIE) ==
        null
      ) {
        this.props.history.push("/");
      }
      //console.log(seg);
      if (n === 2 || n === 17 || n === 32 || n === 47) {
        if (seg === 0) {
          //pedir al servidor
          const res = await instance.get(
            process.env.REACT_APP_URL_MACHOTE + "mapa/nuevo/dr075/7"
          );
          this.setState({ sitios: res.data }, () => {
            this.setState({ contador: this.state.contador + 1 });
          });
          //console.log(this.state.sitios);

          markersLayer.clearLayers();
          markersLayer_2.clearLayers();
          markersLayer_3.clearLayers(); //borrar todas los sitios
          //funcion para armar las gotas y sus respectivos iconos

          for (var i in this.state.sitios) {
            // eslint-disable-next-line
            var fechafinal = "Red Intermitente";
            var latlng = L.latLng({
              lat: this.state.sitios[i].lat,
              lng: this.state.sitios[i].long
            });
            if (this.state.sitios[i].fechalectura !== "red intermitente") {
              fechafinal = this.state.sitios[i].fechalectura;
            }

            if (this.state.sitios[i].tipo === 1) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
                (this.state.sitios[i].caudal === null ||
                this.state.sitios[i].caudal === "red intermitente" ||
                this.state.sitios[i].caudal === null
                  ? this.state.sitios[i].caudal
                  : this.state.sitios[i].caudal.toFixed(3)) +
                " L/S</td><th scope='row'>Volumen:</th><td>" +
                this.state.sitios[i].volumen +
                " M3</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalectura +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = iconcaudalverde;
              } else if (this.state.sitios[i].estatus === "mayor") {
                ic = iconcaudalrojo;
              } else if (this.state.sitios[i].estatus === "cero") {
                ic = iconcaudalamarillo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = iconcaudalamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = iconcaudalverdep;
              } else if (this.state.sitios[i].estatus === "noconexion") {
                ic = iconcaudalnegro;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 2) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
                (this.state.sitios[i].caudal1 === "red intermitente" ||
                this.state.sitios[i].caudal1 === null
                  ? this.state.sitios[i].caudal1
                  : this.state.sitios[i].caudal1.toFixed(3)) +
                " L/S</td><th scope='row'>Volumen:</th><td>" +
                this.state.sitios[i].volumen1 +
                " M3</td></tr><tr><th scope='row'>Caudal:</th><td>" +
                (this.state.sitios[i].caudal2 === "red intermitente" ||
                this.state.sitios[i].caudal2 === null
                  ? this.state.sitios[i].caudal2
                  : this.state.sitios[i].caudal2.toFixed(3)) +
                " L/S</td><th scope='row'>Volumen:</th><td>" +
                this.state.sitios[i].volumen2 +
                " M3</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalectura +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = iconcaudalverde;
              } else if (this.state.sitios[i].estatus === "mayor") {
                ic = iconcaudalrojo;
              } else if (this.state.sitios[i].estatus === "cero") {
                ic = iconcaudalamarillo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = iconcaudalamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = iconcaudalverdep;
              } else if (this.state.sitios[i].estatus === "noconexion") {
                ic = iconcaudalnegro;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 8) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Caudal 1:</th><td>" +
                (this.state.sitios[i].caudal1 === null ||
                this.state.sitios[i].caudal1 === "red intermitente" ||
                this.state.sitios[i].caudal1 === null
                  ? this.state.sitios[i].caudal1
                  : this.state.sitios[i].caudal1.toFixed(3)) +
                " L/S</td><th scope='row'>Volumen 1:</th><td>" +
                this.state.sitios[i].volumen1 +
                " M3</td></tr>";
              if (this.state.sitios[i].volumen2 !== null) {
                customPopup =
                  customPopup +
                  "<tr><th scope='row'>Caudal 2:</th><td>" +
                  (this.state.sitios[i].caudal2 === null ||
                  this.state.sitios[i].caudal2 === "red intermitente" ||
                  this.state.sitios[i].caudal2 === null
                    ? this.state.sitios[i].caudal2
                    : this.state.sitios[i].caudal2.toFixed(3)) +
                  " L/S</td><th scope='row'>Volumen 2:</th><td>" +
                  this.state.sitios[i].volumen2 +
                  " M3</td></tr>";
              }
              if (this.state.sitios[i].volumen3 !== null) {
                customPopup =
                  customPopup +
                  "<tr><th scope='row'>Caudal 3:</th><td>" +
                  (this.state.sitios[i].caudal3 === null ||
                  this.state.sitios[i].caudal3 === "red intermitente" ||
                  this.state.sitios[i].caudal3 === null
                    ? this.state.sitios[i].caudal3
                    : this.state.sitios[i].caudal3.toFixed(3)) +
                  " L/S</td><th scope='row'>Volumen 3:</th><td>" +
                  this.state.sitios[i].volumen3 +
                  " M3</td></tr>";
              }
              if (this.state.sitios[i].volumen4 !== null) {
                customPopup =
                  customPopup +
                  "<tr><th scope='row'>Caudal 4:</th><td>" +
                  (this.state.sitios[i].caudal4 === null ||
                  this.state.sitios[i].caudal4 === "red intermitente" ||
                  this.state.sitios[i].caudal4 === null
                    ? this.state.sitios[i].caudal4
                    : this.state.sitios[i].caudal4.toFixed(3)) +
                  " L/S</td><th scope='row'>Volumen 4:</th><td>" +
                  this.state.sitios[i].volumen4 +
                  " M3</td></tr>";
              }
              customPopup =
                customPopup +
                "<tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalectura +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = iconcaudalverde;
              } else if (this.state.sitios[i].estatus === "mayor") {
                ic = iconcaudalrojo;
              } else if (this.state.sitios[i].estatus === "cero") {
                ic = iconcaudalamarillo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = iconcaudalamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = iconcaudalverdep;
              } else if (this.state.sitios[i].estatus === "noconexion") {
                ic = iconcaudalnegro;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 3) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
                (this.state.sitios[i].cota === null ||
                this.state.sitios[i].cota === "red intermitente" ||
                this.state.sitios[i].cota === null
                  ? this.state.sitios[i].cota
                  : this.state.sitios[i].cota.toFixed(3)) +
                " msnm</td><th scope='row'>Profundidad:</th><td>" +
                this.state.sitios[i].profundidad +
                " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                this.state.sitios[i].temperatura +
                " °C</td><th scope='row'>Presión:</th><td>" +
                this.state.sitios[i].presion +
                " cm H2O</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalectura +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = iconnivelverde;
              } else if (this.state.sitios[i].estatus === "mayor") {
                ic = iconnivelrojo;
              } else if (this.state.sitios[i].estatus === "cero") {
                ic = iconnivelamarillo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = iconnivelamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = iconnivelverdep;
              } else if (this.state.sitios[i].estatus === "noconexion") {
                ic = iconnivellnegro;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer_2.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 4) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
                (this.state.sitios[i].cota1 === null ||
                this.state.sitios[i].cota1 === "red intermitente"
                  ? this.state.sitios[i].cota1
                  : this.state.sitios[i].cota1.toFixed(3)) +
                " msnm</td><th scope='row'>Profundidad:</th><td>" +
                (this.state.sitios[i].profundidad1 === null ||
                this.state.sitios[i].profundidad1 === "red intermitente"
                  ? this.state.sitios[i].profundidad1
                  : this.state.sitios[i].profundidad1.toFixed(3)) +
                " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                this.state.sitios[i].temperatura1 +
                " °C</td><th scope='row'>Presión:</th><td>" +
                (this.state.sitios[i].presion1 === null ||
                this.state.sitios[i].presion1 === "red intermitente"
                  ? this.state.sitios[i].presion1
                  : this.state.sitios[i].presion1.toFixed(3)) +
                " MPa</td></tr>";
              if (this.state.sitios[i].cota2 !== null) {
                customPopup =
                  customPopup +
                  "<tr><th scope='row'>Cota:</th><td>" +
                  (this.state.sitios[i].cota2 === null ||
                  this.state.sitios[i].cota2 === "red intermitente"
                    ? this.state.sitios[i].cota2
                    : this.state.sitios[i].cota2.toFixed(3)) +
                  " msnm</td><th scope='row'>Profundidad:</th><td>" +
                  (this.state.sitios[i].profundidad2 === null ||
                  this.state.sitios[i].profundidad2 === "red intermitente"
                    ? this.state.sitios[i].profundidad2
                    : this.state.sitios[i].profundidad2.toFixed(3)) +
                  " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                  this.state.sitios[i].temperatura2 +
                  " °C</td><th scope='row'>Presión:</th><td>" +
                  (this.state.sitios[i].presion2 === null ||
                  this.state.sitios[i].presion2 === "red intermitente"
                    ? this.state.sitios[i].presion2
                    : this.state.sitios[i].presion2.toFixed(3)) +
                  " MPa</td></tr>";
              }
              if (this.state.sitios[i].cota3 !== null) {
                customPopup =
                  customPopup +
                  "<tr><th scope='row'>Cota:</th><td>" +
                  (this.state.sitios[i].cota3 === null ||
                  this.state.sitios[i].cota3 === "red intermitente"
                    ? this.state.sitios[i].cota3
                    : this.state.sitios[i].cota3.toFixed(3)) +
                  " msnm</td><th scope='row'>Profundidad:</th><td>" +
                  (this.state.sitios[i].profundidad3 === null ||
                  this.state.sitios[i].profundidad3 === "red intermitente"
                    ? this.state.sitios[i].profundidad3
                    : this.state.sitios[i].profundidad3.toFixed(3)) +
                  " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                  this.state.sitios[i].temperatura3 +
                  " °C</td><th scope='row'>Presión:</th><td>" +
                  (this.state.sitios[i].presion3 === null ||
                  this.state.sitios[i].presion3 === "red intermitente"
                    ? this.state.sitios[i].presion3
                    : this.state.sitios[i].presion3.toFixed(3)) +
                  " MPa</td></tr>";
              }
              if (this.state.sitios[i].cota4 !== null) {
                customPopup =
                  customPopup +
                  "<tr><th scope='row'>Cota:</th><td>" +
                  (this.state.sitios[i].cota4 === null ||
                  this.state.sitios[i].cota4 === "red intermitente"
                    ? this.state.sitios[i].cota4
                    : this.state.sitios[i].cota4.toFixed(3)) +
                  " msnm</td><th scope='row'>Profundidad:</th><td>" +
                  (this.state.sitios[i].profundidad4 === null ||
                  this.state.sitios[i].profundidad4 === "red intermitente"
                    ? this.state.sitios[i].profundidad4
                    : this.state.sitios[i].profundidad4.toFixed(3)) +
                  " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                  this.state.sitios[i].temperatura4 +
                  " °C</td><th scope='row'>Presión:</th><td>" +
                  (this.state.sitios[i].presion4 === null ||
                  this.state.sitios[i].presion4 === "red intermitente"
                    ? this.state.sitios[i].presion4
                    : this.state.sitios[i].presion4.toFixed(3)) +
                  " MPa</td></tr>";
              }
              customPopup =
                customPopup +
                "<tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalectura +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = iconnivelverde;
              } else if (this.state.sitios[i].estatus === "mayor") {
                ic = iconnivelrojo;
              } else if (this.state.sitios[i].estatus === "cero") {
                ic = iconnivelamarillo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = iconnivelamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = iconnivelverdep;
              } else if (this.state.sitios[i].estatus === "noconexion") {
                ic = iconnivellnegro;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer_2.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 5) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
                this.state.sitios[i].caudal +
                " L/S</td><th scope='row'>Volumen:</th><td>" +
                this.state.sitios[i].volumen +
                " M3</td></tr><tr><tr><th scope='row'>Voltaje 1:</th><td>" +
                this.state.sitios[i].voltaje_1 +
                " V</td><th scope='row'>Corriente 1:</th><td>" +
                this.state.sitios[i].corriente_1 +
                " M3</td></tr><tr><th scope='row'>Potencia 1:</th><td>" +
                this.state.sitios[i].potencia_1 +
                " V</td><th scope='row'>Energia 1:</th><td>" +
                this.state.sitios[i].energia_1 +
                " V</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalectura +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = iplcverde;
              } else if (this.state.sitios[i].estatus === "mayor") {
                ic = iplcrojo;
              } else if (this.state.sitios[i].estatus === "cero") {
                ic = iplcama;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = iplcama;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = iplcverdep;
              } else if (this.state.sitios[i].estatus === "noconexion") {
                ic = iplcnegro;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer_3.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 6) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody>";

              if (this.state.sitios[i].caudal !== null) {
                customPopup =
                  customPopup +
                  "<tr><th scope='row'>Caudal:</th><td>" +
                  this.state.sitios[i].caudal +
                  " L/S</td><th scope='row'>Volumen:</th><td>" +
                  this.state.sitios[i].volumen +
                  " M3</td></tr>";
              }
              customPopup =
                customPopup +
                "<tr><th scope='row'>Temperatura:</th><td>" +
                this.state.sitios[i].temperatura +
                " C°</td><th scope='row'>Elevación:</th><td>" +
                this.state.sitios[i].elevacion +
                " </td></tr><tr><th scope='row'>Conductividad:</th><td>" +
                this.state.sitios[i].conductividad +
                " </td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalectura +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = imultiverde;
              } else if (this.state.sitios[i].estatus === "mayor") {
                ic = imultirojo;
              } else if (this.state.sitios[i].estatus === "cero") {
                ic = imultiama;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = imultiama;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = imultiverdep;
              } else if (this.state.sitios[i].estatus === "noconexion") {
                ic = imultinegro;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer.addLayer(marker);
            } else if (this.state.sitios[i].tipo === 7) {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Caudal:</th><td>" +
                this.state.sitios[i].caudal +
                " L/S</td><th scope='row'>Volumen:</th><td>" +
                this.state.sitios[i].volumen +
                " M3</td></tr><tr><tr><th scope='row'>Voltaje 1:</th><td>" +
                this.state.sitios[i].voltaje_1 +
                " V</td><th scope='row'>Corriente 1:</th><td>" +
                this.state.sitios[i].corriente_1 +
                " M3</td></tr><tr><th scope='row'>Potencia 1:</th><td>" +
                this.state.sitios[i].potencia_1 +
                " V</td><th scope='row'>Energia 1:</th><td>" +
                this.state.sitios[i].energia_1 +
                " V</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalectura +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = iplcverde;
              } else if (this.state.sitios[i].estatus === "mayor") {
                ic = iplcrojo;
              } else if (this.state.sitios[i].estatus === "cero") {
                ic = iplcama;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = iplcama;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = iplcverdep;
              } else if (this.state.sitios[i].estatus === "noconexion") {
                ic = iplcnegro;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer_3.addLayer(marker);
            } else {
              customPopup =
                " <div className='container'><div className='row'><div className='col-12 table-responsive-sm'><table className='table table-striped table-sm table-dark'><thead><tr><th colSpan='4' scope='col' className='text-center'>" +
                this.state.sitios[i].nombre +
                "</th></tr></thead><tbody><tr><th scope='row'>Cota:</th><td>" +
                this.state.sitios[i].cota +
                " msnm</td><th scope='row'>Profundidad:</th><td>" +
                this.state.sitios[i].profundidad +
                " mbs</td></tr><tr><th scope='row'>Temperatura:</th><td>" +
                this.state.sitios[i].temperatura +
                " °C</td><th scope='row'>Presión:</th><td>" +
                this.state.sitios[i].presion +
                " MPa</td></tr><tr><th colSpan='2' scope='row'>Fecha de Lectura</th><td colSpan='2'>" +
                this.state.sitios[i].fechalectura +
                "</td></tr><tr><th colSpan='4' ><a className='btn btn-primary btn-sm btn-block' target='_blank' rel='noopener noreferrer' href='/dashboard/" +
                this.state.sitios[i].tipo +
                "/" +
                this.state.sitios[i].idSitio +
                "'>Ir Al Sitio</a></th></tr></tbody></table></div></div></div>";

              if (this.state.sitios[i].estatus === "normal") {
                ic = iconcaudalverde;
              } else if (this.state.sitios[i].estatus === "mayor") {
                ic = iconcaudalrojo;
              } else if (this.state.sitios[i].estatus === "cero") {
                ic = iconcaudalamarillo;
              } else if (this.state.sitios[i].estatus === "menor") {
                ic = iconcaudalamarillo;
              } else if (this.state.sitios[i].estatus === "redint") {
                ic = iconcaudalverdep;
              } else if (this.state.sitios[i].estatus === "noconexion") {
                ic = iconcaudalnegro;
              }

              marker = L.marker(latlng, { icon: ic }).bindPopup(
                customPopup,
                customOptions
              );
              markersLayer_2.addLayer(marker);
            }
          }
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div>
        <CuadroAlertas
          sitios={this.state.sitios}
          contador={this.state.contador}
        />
        <div className="container-fluid" style={{ padding: "0" }}>
          <div className="row">
            <Wrapper
              className="col-12"
              width="100vh"
              height="90vh"
              id="map"
            ></Wrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(CargarPuntos2);
